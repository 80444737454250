.create-post-title {
    width: 100%;

    .create-market-type-input {
        width: 12%;
        padding: 1% 1%;
        font-family: "YeolrinGothic-Light";
        font-size: 15px;
        margin-right: 2%;
    }
    .create-market-title-input {
        width: 86%;
        padding: 1% 1%;
        font-family: "YeolrinGothic-Light";
        font-size: 15px;
    }

    margin-top: 1%;
    margin-bottom: 3%;
}
