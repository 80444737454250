#signupFormWrapper{
    display : flex;
    flex-direction : column;
    width : 100%
}
.innerInput{
    display : flex;
    flex-direction : row;
    justify-content : space-between;
    font-size : 120%;
    align-items : center;
    margin-left : 30%;
    margin-right : 30%;
    margin-top : 3.5%;
    input{
        font-size : 80%;
        width : 300px;
        height : 30px;
    }
}
.inputText{
    font-size : 120%;
}
#emailInput{
    margin-left : 13.5%;
    width : 200px;    
}
.outerSignupForm{
    display : flex;
}
#emailAuth{
    display : flex;
    justify-content : center;
    align-items : center;
    font-size : 0.7rem;
    width : 60px;
    height : 40px;
    &:hover{
        cursor : pointer;
        opacity :80%;
    }
}
#signupSubmitBtn{
    &:hover{
        opacity : 80%;
        cursor : pointer;
    }
}