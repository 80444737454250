* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

input:focus {
    outline: none;
}

textarea {
    resize: none;
}

textarea:focus {
    outline: none;
}

@font-face {
    font-family: "YeolrinGothic-Bold";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Bold.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "YeolrinGothic-Medium";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Medium.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "YeolrinGothic-Light";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Light.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

:root {
    /* Colors: */
    --unnamed-color-000000: #000000;
    --unnamed-color-1d333e: #1d333e;
    --unnamed-color-ffffff: #ffffff;
    --unnamed-color-707070: #707070;
    --unnamed-color-f5f5f5: #f5f5f5;
    --unnamed-color-1d283e: #1d283e;
    --unnamed-color-fafafa: #fafafa; /* Font/text values */
    --unnamed-font-family-yeolringothic: YeolrinGothic;
    --unnamed-font-family-nanumgothic: NanumGothic;
    --unnamed-font-family-segoe-ui: Segoe UI;
    --unnamed-font-family-pt-sans-caption: PT Sans Caption;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-300: 300px;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-size-9: 9px;
    --unnamed-font-size-10: 10px;
    --unnamed-font-size-11: 11px;
    --unnamed-font-size-12: 12px;
    --unnamed-font-size-13: 13px;
    --unnamed-font-size-15: 15px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-100: 100px;
    --unnamed-font-size-27-5: 27.5px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-10: 10px;
    --unnamed-line-spacing-14: 14px;
    --unnamed-line-spacing-15: 15px;
    --unnamed-line-spacing-16: 16px;
    --unnamed-line-spacing-17: 17px;
    --unnamed-line-spacing-20: 20px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-26: 26px;
    --unnamed-line-spacing-37: 37px;
    --unnamed-line-spacing-133: 133px;
}

header {
    // position: fixed;
    // top: 0px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 99px;
    padding: 0 30px;
    box-shadow: 0 10px 10px #00000029;
    font-family: "YeolrinGothic-Light";
    // white-space: nowrap;

    .headerLogoSection {
        width: 20%;
        .header-logo {
            display: flex;
            align-items: flex-end;
            width: 100%;
            border: 0px;
            .header-logo-img {
                width: 100%;
            }
        }
    }

    .headerListSection {
        width: 62%;
        .header-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 22px;
            font-family: "YeolrinGothic-Medium";

            .header-list-item-text {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto 0%;
            }

            .header-list-item {
                /* Layout Properties */
                align-content: center;
                padding-top: auto;
                padding-bottom: auto;
                width: 20%;
                height: 99px;
                text-align: center;

                /* UI Properties */
                font: var(--unnamed-font-style-normal) normal
                    var(--unnamed-font-weight-medium)
                    var(--unnamed-font-size-12) / var(--unnamed-line-spacing-16)
                    var(--unnamed-font-family-yeolringothic);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-1d283e);
                font: normal normal bold 16px YeolrinGothic;
                letter-spacing: 0px;
                color: #1d283e;
                opacity: 1;

                .dropdown {
                    display: none;
                    width: 100%;
                    height: auto;
                    flex-direction: column;
                    align-content: center;
                    background: rgba(0, 0, 0, 0.5);
                    padding: 8px 0px;

                    .dropdown-item {
                        width: 100%;
                        display: block;
                        text-align: center;
                        color: white;
                        font-family: "YeolrinGothic-Light";
                        font-size: 14px;
                        padding: 7px 0px;
                    }
                    .dropdown-item:hover {
                        cursor: pointer;
                    }
                }
            }
            .header-list-item:hover {
                cursor: pointer;
            }
            .header-list-item:hover div.dropdown {
                display: flex;
            }
        }
    }
}

.title-banner {
    height: 150px;
    // margin-top: 99px;
    padding-left: 79px;
    line-height: 150px;
    background: #1d333e;
    font-family: "YeolrinGothic-Bold";
    font-size: 28px;
    color: white;
}

.logInSection {
    width: 18%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex: 1;

    .logInSectionText {
        flex: 1;
        font-family: "YeolrinGothic-Medium";
        // width: 33%;
        height: 100%;
        text-align: center;
        font: normal normal bold 16px YeolrinGothic;
        letter-spacing: 0px;
        color: #1d283e;

        .searchIcon {
            padding-top: 10%;
            width: 30%;
            &:hover{
                cursor : pointer;
            }
        }
    }
}

// #search-button {
//     /* Layout Properties */
//     width: 12px;
//     height: 12px; /* UI Properties */
//     background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
//     border: 1px solid var(--unnamed-color-1d283e);
//     background: #ffffff 0% 0% no-repeat padding-box;
//     border: 0px solid #1d283e;
//     opacity: 1;
// }

// #log-in {
//     /* Layout Properties */
//     width: 36px;
//     height: 16px; /* UI Properties */
//     font: var(--unnamed-font-style-normal) normal
//         var(--unnamed-font-weight-medium) var(--unnamed-font-size-12) /
//         var(--unnamed-line-spacing-16) var(--unnamed-font-family-yeolringothic);
//     letter-spacing: var(--unnamed-character-spacing-0);
//     color: var(--unnamed-color-1d283e);
//     text-align: left;
//     font: normal normal bold 16px YeolrinGothic;
//     letter-spacing: 0px;
//     color: #1d283e;
//     opacity: 1;
// }

// #sign-up {
//     /* Layout Properties */
//     width: 48px;
//     height: 16px; /* UI Properties */
//     font: var(--unnamed-font-style-normal) normal
//         var(--unnamed-font-weight-medium) var(--unnamed-font-size-12) /
//         var(--unnamed-line-spacing-16) var(--unnamed-font-family-yeolringothic);
//     letter-spacing: var(--unnamed-character-spacing-0);
//     color: var(--unnamed-color-1d283e);
//     text-align: left;
//     font: normal normal bold 16px YeolrinGothic;
//     letter-spacing: 0px;
//     color: #1d283e;
//     opacity: 1;
// }

/*# sourceMappingURL=base.css.map */
