.loginForm{
    width : 100%;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    margin-top : 4%;
}
#idDiv{
    width : 100vw;
    padding-left : 29%;
    padding-right : 29%;
    font-size : 180%;
    display : flex;
    flex-direction : row;
    justify-content : space-between;
    align-items : center;
    #idInput{
        // margin-left : 50%;
        font-size : 80%;
        width : 350px;
    }
}

#pwDiv{    
    width : 100vw;
    padding-left : 29%;
    padding-right : 29%;
    font-size : 180%;
    display : flex;
    flex-direction : row;
    justify-content : space-between;
    align-items : center;
    margin-top : 3%;
    #pwInput{
        // margin-left : 50%;
        font-size : 80%;
        width : 350px; 
    }
}
#loginSubmitBtn{
    display : flex;
    justify-content : center;
    align-items : center;
    margin-top : 6%;
    width : 280px;
    height : 80px;
    background-color : #1D333E;
    font-size : 100%;
    margin-left : 40%;
    margin-right : 25%;
    color : white;
    &:hover{
        cursor : pointer;
        opacity : 85%;
    }
}
#loginControl{
    display : flex;
}

#navFindPw, #navSignup{
    &:hover{
        cursor : pointer;
        color : #1D333E;
    }
}
