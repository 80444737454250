.post-list-author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .post-list-author-img {
        display: flex;
        flex-direction: row;
        padding-right: 0%;
    }
}

.post-list-head-market {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.post-list-main {
    .post-list-main-element {
        display: flex;
        flex-direction: row;
        padding: 8px 0;
        border-bottom: 1px solid black;

        .post-list-market-type {
            width: 5%;
        }
        .post-list-main-market-title {
            width: 55%;
        }
        .post-list-main-createdAt {
            width: 20%;
        }
        .post-list-main-author {
            width: 20%;
        }
    }
}

.post-gallery-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .market-gallery-element {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        border-bottom: 1px solid black;
        width: 24%;
        margin-right: 1%;

        .market-gallery-element-photo-section {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-bottom: 100%;
            margin-bottom: 8px;
        }

        .market-gallery-element-text-section {
            display: flex;
            flex-direction: row;
            .post-list-market-type {
            }
            .post-list-main-market-title {
            }
        }
    }
}
