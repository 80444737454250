.post-contents {
    .post-contents-title {
        font-size: 23px;
        margin-bottom: 23px;
        font-weight: bold;
    }

    .post-contents-info {
        display: flex;
        flex-direction: row;
        margin-bottom: 23px;
        align-items: center;
        .post-contents-info-author {
            margin-right: 3%;
        }
        .post-contents-info-date {
            margin-right: 3%;
        }
        .post-contents-info-views {
            margin-right: 3%;
        }
    }

    .post-contents-content-label {
        // padding-bottom: 23px;
        border-bottom: 1px solid #566b64;
        margin-bottom: 23px;
    }

    .post-contents-content {
        min-height: 50vh;
        margin-bottom: 3%;
    }

    .post-photos {
        width: 80%;
    }

    .comments-section {
        border-top: 1px solid #566b64;
        margin: 23px 0px;

        .comments-section-label {
            margin-top: 23px;
            font-weight: bold;
            padding-bottom: 23px;
            border-bottom: 1px solid #566b64;
        }

        .real-comment {
            padding: 23px 0px;
            border-bottom: 1px solid #566b64;
            display: flex;
            flex-direction: column;

            .real-comment-content {
                margin-right: 3%;
            }
            .real-comment-author-and-createAt {
                font-size: 13px;
                display: flex;
                flex-direction: row;
                margin-bottom: 1%;
                align-items: baseline;

                .real-comment-author {
                    margin-right: 2%;
                }
                .real-comment-createdAt {
                }
            }

            .comment-delete-button {
                margin-top: 1%;
            }
        }

        .comment-form-wrap {
            margin-top: 23px;
            width: 100%;
            .comments-section-main {
                width: 100%;
                display: flex;
                flex: column;
                justify-content: space-between;
                align-items: center;

                .create-comment {
                    height: 40px;
                    width: 80%;
                    .create-comment-input {
                        height: 40px;
                        width: 100%;
                        padding: 1% 1%;
                        font-family: "YeolrinGothic-Light";
                        font-size: 15px;
                    }
                }

                .create-comment-button {
                    width: 15%;
                    height: 30px;
                }
            }
        }
    }
}
