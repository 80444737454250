#myCommitteeSection, #myCommunitySection, #myNoticeSection, #myMajorSection{
    margin-top : 5%;
    // margin : auto;
    margin-left : 5%;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    width : 90%;
    height : 60vh;
    border-top : 4px solid #7B8A78;   
}
.moreCommitteeSentence, .moreCommunitySentence, .moreMajorSentence, .moreNoticeSentence{
    &:hover{
        cursor : pointer;
        opacity : 80%;
    }
}
#searchSubmitBtn{
    &:hover{
        cursor : pointer;
    }
}